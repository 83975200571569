import request from './request';

/**
 * @description 轮播图
 */
export function bannerApi(data) {
	return request.get(`api/index/banner`, data);
}
// 产品分类 
export function categoryApi(data) {
	return request.get(`api/goods/category`, data);
}
// 设计加工产品分类 
export function shejijiagongApi(data) {
	return request.get(`api/programme/shejijiagong`, data);
}
// 根据父类获取子类
export function categoryInfoApi(data) {
	return request.get(`api/goods/category_info`, data);
}
// 规格详情
export function categoryFindApi(data) {
	return request.get(`api/goods/category_find`, data);
}
// 案例分析和设计加工的分类
export function programmeTypeApi(data) {
	return request.get(`api/programme/type`, data);
}
// 光学中心
export function programmeApi(data) {
	return request.get(`api/programme/programme`, data);
}
// 光学中心详情
export function programmeInfoApi(data) {
	return request.get(`api/programme/programme_info`, data);
}
// 设计加工
export function designApi(data) {
	return request.get(`api/programme/design`, data);
}
// 设计加工详情
export function designInfoApi(data) {
	return request.get(`api/programme/design_info`, data);
}
// 设计加工推荐
export function designRecommendApi(data) {
	return request.get(`api/programme/design_recommend`, data);
}
// 企业介绍;
export function enterpriseApi(data) {
	return request.get(`api/programme/enterprise`, data);
}
// 企业信息
export function corporateApi(data) {
	return request.get(`api/programme/corporate`, data);
}
// 新品展示
export function productApi(data) {
	return request.get(`api/index/product`, data);
}
// 商品搜索
export function goodsSearchApi(data) {
	return request.get(`api/goods/goods_search`, data);
}
// 商品列表
export function goodsApi(data) {
	return request.get(`api/goods/goods`, data);
}
// 加入购物车
export function addCarApi(data) {
	return request.get(`api/car/add_car`, data);
}
// 修改购物车数量
export function carNumApi(data) {
	return request.get(`api/car/car_num`, data);
}
// 清空购物车
export function carClearApi(data) {
	return request.get(`api/car/car_clear`, data);
}
// 我的购物车
export function myCarApi(data) {
	return request.get(`api/car/my_car`, data);
}
// 我的订单
export function orderApi(data) {
	return request.get(`api/order/order`, data);
}
// 创建订单
export function orderAddApi(data) {
	return request.post(`api/order/order_add`, data);
}
// 订单详情 
export function orderInfoApi(data) {
	return request.post(`api/order/order_info`, data);
}
// 保存发票信息收货信息
export function orderInvoiceApi(data) {
	return request.post(`api/order/add_invoice`, data);
}
/**
 * @description 发送邮件
 */
export function sendEmailApi(data) {
	return request.get(`api/order/send_email`, data);
}
/**
 * @description 我的发票信息
 */
export function invoiceApi(data) {
	return request.get(`api/order/invoice`, data);
}
/**
 * @description 商品详情
 */
export function goodsInfoApi(data) {
	return request.get(`api/goods/goods_info`, data);
}